/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {
        // JavaScript to be fired on all pages

        $(window).scroll(function() {
        if ($(this).scrollTop() > 30){
            $('header.navbar-fixed-top').addClass("sticky");
          }
          else{
            $('header.navbar-fixed-top').removeClass("sticky");
          }
        });

        // Remove active from anchor links with class scrollzone
        $(document).ready(function(){
          if ( $(".nav li").hasClass("scrollzone") ) {
            $(".nav li").removeClass("active");
          }
        });
        // End of Remove active from anchor links


        // Submit localize form
        $('.localize').click(function(e) {
          e.preventDefault();
          $('#localize').submit();
        });

          // End of Submit localize form


        // Open modals
         $('.openmodal a[href*=#]:not([href=#])').click(function() {
           var target = $(this).attr('href');
           $('.openmodal a').attr('data-toggle', 'modal');
           $('.openmodal a').attr('data-target', target);
         });
        // End of open modals

        // Change the ajax loader behaviour on ready to scale
        // Show new spinner on Send button click

        $(function() {
        // End of Change the ajax loader behaviour on ready to scale
          $('.ajax-loader').css({ display: 'none' });
        });

        $('.wpcf7-submit').on('click', function () {
            $('.ajax-loader').css({ display: 'block' });
        });

        // Hide new spinner on result
        $('div.wpcf7').on('wpcf7:invalid wpcf7:spam wpcf7:mailsent wpcf7:mailfailed', function () {
            $('.ajax-loader').css({ display: 'none' });
        });
        // End of contact form 7 fix



      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired
      }
    },
    // Home page
    'home': {
      init: function() {
        // JavaScript to be fired on the home page

        // Scrollto to section nav
        $(".home li.menu-item a[href$='#apartments']").click(function(){
          event.preventDefault();
          var section = '#apartments';
          $('html, body').animate({
              scrollTop: $(section).offset().top - 80
          },900);
          $('.navbar-toggle:visible').click();
        });

        $(".home .jumbotron a[href$='#apartments']").click(function(){
          event.preventDefault();
          var section = '#apartments';
          $('html, body').animate({
              scrollTop: $(section).offset().top - 80
          },900);
        });


      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
    // About us page, note the change from about-us to about_us.
    'about_us': {
      init: function() {
        // JavaScript to be fired on the about us page
      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
